<template>
  <div>
    <div>
      <a-row :gutter="24">
        <a-col
          style="padding: 0 12px"
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24">
          <a-card title="快速开始 / 便捷导航" style="margin-bottom: 24px" :bordered="false" :body-style="{padding: 0}">
            <div class="item-group" v-if="false">
              <router-link :to="{ name: 'InOut' }">
                便捷出入区申请单
              </router-link>
              <router-link :to="{ name: 'Temporary' }">
                入区登记
              </router-link>
              <router-link :to="{ name: 'muck' }">
                渣土记录
              </router-link>
              <router-link :to="{ name: 'Book' }">
                便捷出入区台账
              </router-link>
            </div>
            <a-card-grid
              style="width:25%;text-align:center"
              :hoverable="true"
              v-for="(item, index) in use_quick_navigations"
              :key="index">
              <router-link :to="{ name: item.name }" :is="isEdited ? 'span' : 'router-link'">
                {{ item.meta.title }}
              </router-link>
              <a-checkbox :checked="item.checked" @change="onCheckRouterChange(item)" v-if="isEdited">
              </a-checkbox>
              <!--              <span @click="toRouter(item)">{{ item.meta.title }}</span>-->
            </a-card-grid>
            <div slot="extra">
              <a-button type="primary" @click="commitRouter" v-if="isEdited" style="margin-right: 10px">提交</a-button>
              <a @click="editRouter">{{ editText }}</a>
            </div>
          </a-card>
          <a-card
            v-if="false"
            title="更新日志"
            style="margin-bottom: 24px;"
            :bordered="false"
            :body-style="{ padding: 0 }">
            <div style="height: 450px;padding: 30px;overflow:auto;">
              <timeline :timeline-list="dongtai"></timeline>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { timeFix } from '@/utils/util'
import { mapState } from 'vuex'
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { Radar } from '@/components'
import Timeline from './timeline'
import { getShortCut, putShortCut } from '@/api/login'
export default {
  name: 'Workplace',
  components: {
    PageHeaderWrapper,
    Radar,
    Timeline
  },
  data () {
    return {
      timeFix: timeFix(),
      avatar: '',
      user: {},
      quick_navigations: [],
      checked_quick_navigations: [],
      use_quick_navigations: [],
      isEdited: false,
      dongtai: [
        { date: '20211014',
          title: 'v2.0.6',
          content: ' · 新增导入功能\n' +
            ' · 修改下载接口\n' +
            ' · 修改样式布局问题\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210930',
          title: 'v2.0.5',
          content: ' · 新增用户提醒\n' +
            ' · 修改数字加载问题\n' +
            ' · 修改样式布局问题\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210924',
          title: 'v2.0.4',
          content: ' · 新增保质期提醒\n' +
            ' · 新增回车搜索功能\n' +
            ' · 修改用户搜索企业问题\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210917',
          title: 'v2.0.3',
          content: ' · 新增人民币汇率\n' +
            ' · 新增汇率配置\n' +
            ' · 修改样式细节\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210910',
          title: 'v2.0.2',
          content: ' · 新增退款\n' +
            ' · 新增公司配置浪潮QID\n' +
            ' · 修改样式细节\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210903',
          title: 'v2.0.1',
          content: ' · 新增申报单\n' +
            ' · 新增QID\n' +
            ' · 修改样式细节\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210827',
          title: 'v2.0.0',
          content: ' · 新增更换公司功能\n' +
            ' · 修改面包屑模块\n' +
            ' · 修改样式细节\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210508',
          title: 'v1.1.0',
          content: ' · 新增入区登记通知功能\n' +
            ' · 新增入区登记通知管理模块\n' +
            ' · 新增通知权限\n' +
            ' · 优化出入区提示信息'
        },
        { date: '20210430',
          title: 'v1.0.9',
          content: ' · 新增入区登记车牌插件\n' +
            ' · 新增入区登记用户微信信息获取\n' +
            ' · 优化小程序表单错误提示功能\n' +
            ' · 优化微信getuserinfo接口的兼容性\n'
        },
        { date: '20210423',
          title: 'v1.0.8',
          content: ' · 增加结算货币单位\n' +
            ' · 增加时间格式处理\n' +
            ' · 增加接口提示信息\n' +
            ' · 优化web端功能模块\n'
        },
        { date: '20210416',
          title: 'v1.0.7',
          content: ' · 增加小程序车牌号输入插件\n' +
            ' · 增加小程序车牌验证功能\n' +
            ' · 增加web端车牌填写插件\n' +
            ' · 增加表单输入长度限制规则\n' +
            ' · 优化主页快捷导航\n'
        },
        { date: '20210409',
          title: 'v1.0.6',
          content: ' · 优化入区登记\n' +
            ' · 优化表单验证\n' +
            ' · 更新小程序入区登记功能\n' +
            ' · 更新入区登记筛选功能\n' +
            ' · 修复web端测试缺陷\n'
        },
        { date: '20210402',
          title: 'v1.0.5',
          content: ' · 新增入区登记\n' +
            ' · 新增用户公司关联\n' +
            ' · 更新打印功能\n' +
            ' · 更新非零筛选功能\n' +
            ' · 修复web端测试缺陷\n'
        },
        { date: '20210326',
          title: 'v1.0.4',
          content: ' · 新增渣土模块\n' +
            ' · 新增新建用户关联关系\n' +
            ' · 新增pdf上传\n' +
            ' · 更新仓库展示记录\n' +
            ' · 修复web端测试缺陷\n'
        },
        { date: '20210319',
          title: 'v1.0.3',
          content: ' · 新增仓库模块\n' +
            ' · 新增物品基建类功能模块\n' +
            ' · 新增机构端公司选择模块\n' +
            ' · 新增管理端标签信息\n' +
            ' · 新增用户默认头像\n' +
            ' · 更新菜单icon\n' +
            ' · 修复web端测试缺陷'
        },
        { date: '20210319',
          title: 'v1.0.3',
          content: ' · 新增仓库模块\n' +
            ' · 新增物品基建类功能模块\n' +
            ' · 新增机构端公司选择模块\n' +
            ' · 新增管理端标签信息\n' +
            ' · 新增用户默认头像\n' +
            ' · 更新菜单icon\n' +
            ' · 修复web端测试缺陷'
        },
        { date: '20210312',
          title: 'v1.0.2',
          content: ' · 新增小程序端便捷出入区的功能\n' +
            ' · 修改小程序便捷出入区填写方式\n' +
            ' · 修复管理端便捷出入区物品添加的样式\n' +
            ' · 优化园区系统管理端的一些样式\n' +
            ' · 优化园区系统企业端的一些样式'
        },
        { date: '20210306',
          title: 'v1.0.1',
          content: ' · dian 新增微信小程小程序『我的』页面\n' +
            ' · 新增微信小程解绑功能\n' +
            ' · 新增管理端首页\n' +
            ' · 修复了一些体验不佳的功能及样式'
        },
        { date: '20210301',
          title: 'v1.0.0',
          content: '- 系统上线'
        }
      ],
      projects: [],
      loading: true,
      radarLoading: true,
      activities: [],
      teams: [],

      // data
      axis1Opts: {
        dataKey: 'item',
        line: null,
        tickLine: null,
        grid: {
          lineStyle: {
            lineDash: null
          },
          hideFirstLine: false
        }
      },
      axis2Opts: {
        dataKey: 'score',
        line: null,
        tickLine: null,
        grid: {
          type: 'polygon',
          lineStyle: {
            lineDash: null
          }
        }
      },
      radarData: []
    }
  },
  computed: {
    ...mapState({
      nickname: (state) => state.user.nickname,
      welcome: (state) => state.user.welcome
    }),
    userInfo () {
      return this.$store.getters.userInfo
    },
    editText () {
     return this.isEdited ? '取消' : '编辑'
    }
  },
  created () {
    this.user = this.userInfo
    console.log(this.userInfo, '用户信息')
    this.avatar = this.userInfo.avatar
  },
  mounted () {
    this.quickNavigation()
    this.getMyRouters()
  },
  methods: {
    quickNavigation () {
      const routers = this.$store.state.permission.addRouters[0].children
      console.log(this.$store.state.permission, '000000000000000000000')
      routers.filter(option => {
        return !option.hidden
      }).map(option => {
        if (option.children) {
          option.children = option.children.map(child => {
            if (child.children) {
              child.children = child.children.map(c => {
                if (c.path.indexOf(child.path) === -1 && c.path.indexOf('/') !== 0 && child.path.indexOf('/') !== 0) {
                  const fullPath = [option.path, child.path, c.path].join('/')
                  c.path = fullPath
                }
                return c
              })
              this.quick_navigations.push(...child.children)
            } else {
              if (child.path.indexOf(option.path) === -1 && child.path.indexOf('/') !== 0) {
                const fullPath = [option.path, child.path].join('/')
                child.path = fullPath
              }
              this.quick_navigations.push(child)
            }
            return child
          })
        }
        return option.children < 0
      })
      // this.quick_navigations.push(...navigations)
      this.quick_navigations = this.quick_navigations.filter(item => {
        return !item.hidden
      })
    },
    getMyRouters () {
      const params = {
        user_id: this.user.id,
        corporation_id: -1
      }
      getShortCut(params).then(res => {
        this.checked_quick_navigations = res.data
        this.checked_quick_navigations = this.checked_quick_navigations.map(item => {
          const name = item.title
          item.meta = {
            title: name
          }
          return item
        })
        const navigations = this.checked_quick_navigations.map(item => {
          return item.name
        })
        this.quick_navigations = this.quick_navigations.map(item => {
          if (navigations.findIndex(v => {
            return v === item.name
          }) > -1) {
            item.checked = true
          } else {
            item.checked = false
          }
          return item
        })
      }).finally(() => {
        this.use_quick_navigations = this.isEdited ? this.quick_navigations : this.checked_quick_navigations
        console.log(this.quick_navigations, '1111111111111')
        console.log(this.use_quick_navigations, '1111111111111')
      })
    },
    toRouter (item) {
      console.log(item)
      this.$router.push(item.path)
    },
    editRouter () {
      this.isEdited = !this.isEdited
      this.use_quick_navigations = this.isEdited ? this.quick_navigations : this.checked_quick_navigations
    },
    onCheckRouterChange (selectedItem) {
      this.quick_navigations = this.quick_navigations.map(item => {
        if (selectedItem === item) {
          item.checked = !item.checked
        }
        return item
      })
      this.use_quick_navigations = this.quick_navigations
    },
    commitRouter () {
      const values = this.quick_navigations.filter(item => {
        return item.checked
      }).map(item => {
        return {
          path: item.path,
          name: item.name,
          title: item.meta.title
        }
      })
      const data = {
        user_id: this.user.id,
        corporation_id: -1,
        shortcut_list: values
      }
      if (data) {
        console.log(data)
      }
      putShortCut(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.isEdited = false
          this.getMyRouters()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./Workplace.less";

  .project-list {

    .card-title {
      font-size: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 12px;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .card-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }

    .project-item {
      display: flex;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      height: 20px;
      line-height: 20px;

      a {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        flex: 1 1 0;

        &:hover {
          color: #1890ff;
        }
      }

      .datetime {
        color: rgba(0, 0, 0, 0.25);
        flex: 0 0 auto;
        float: right;
      }
    }

    .ant-card-meta-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
  }

  .item-group {
    padding: 20px 0 8px 24px;
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      font-size: 14px;
      margin-bottom: 13px;
      width: 25%;
    }
  }

  .members {
    a {
      display: block;
      margin: 12px 0;
      line-height: 24px;
      height: 24px;

      .member {
        font-size: 14px;
        color: rgba(0, 0, 0, .65);
        line-height: 24px;
        max-width: 100px;
        vertical-align: top;
        margin-left: 12px;
        transition: all 0.3s;
        display: inline-block;
      }

      &:hover {
        span {
          color: #1890ff;
        }
      }
    }
  }

  .mobile {

    .project-list {

      .project-card-grid {
        width: 100%;
      }
    }

    .more-info {
      border: 0;
      padding-top: 16px;
      margin: 16px 0 16px;
    }

    .headerContent .title .welcome-text {
      display: none;
    }
  }

</style>
